/*jslint browser: true*/
/*global angular, console */
(function () {
    "use strict";

    var module = angular.module('ijsVideoplayer');

    module.directive('ijsVideoTimeline', [ '$filter', 'ijsVideoService',function ($filter, ijsVideoService) {
        return {
            restrict: 'E',
            transclude: true,
            replace: true,
            template: '<div class="video-timeline">' +
                        '<div ng-click="bufferClick($event)" class="timeline-bar buffered"></div>' +
                        '<div ng-click="timelineClick($event)" class="timeline-bar progress"></div>' +
                        '<div class="progress-time"></div>' +
                        '<div ng-show="event" ng-click="eventClick()" class="event-button">{{event.label}}</div>' +
                    '</div>',
            link: function link(scope, element) {

                var progressBar     = element.find('div')[1],
                    bufferedBar     = element.find('div')[0],
                    progressTime    = element.find('div')[2];

                // Observe the `lastUpdate` which provides a live data-stream when a
                // video is playing
                scope.$watch('lastUpdate', function watch() {

                    var buffered    = scope.videoElement.buffered,
                        duration    = scope.duration,
                        percentage  = scope.percentagePlayed;

                    scope.event = false;

                    if(buffered.length > 0){

                        var timeBuffered        = buffered.end(buffered.length - 1);
                        var bufferedPercentage  = (timeBuffered/duration)*100;

                        angular.element(bufferedBar).css('width',bufferedPercentage+'%');
                    }

                    angular.element(progressBar).css('width',percentage+'%');

                    var currentTime = $filter('date')(scope.currentTime*1000, 'mm:ss');
                    var duration    = $filter('date')(duration*1000, 'mm:ss');

                    angular.element(progressTime).html(currentTime + ' / ' + duration);

                    if(ijsVideoService.timedEvents){
                        scope.event = ijsVideoService.hasEvent(scope.currentTime);
                    }
                });

                scope.eventClick = function(){
                    ijsVideoService.eventClick(scope.event);
                }

                scope.timelineClick = function($event){
                    handleTimelineClick(progressBar, $event);
                }

                scope.bufferClick = function($event){
                    handleTimelineClick(bufferedBar, $event);
                }

                function handleTimelineClick(clickedElement, clickEvent){

                    var rectProgress    = clickedElement.getBoundingClientRect();
                    var rectBar         = element[0].getBoundingClientRect();
                    var elemementWidth  = rectBar.right - rectBar.left;
                    var deltaX          = clickEvent.clientX - rectProgress.left;
                    var percentage      = (deltaX/elemementWidth);
                    var jumpToTime      = scope.duration*percentage;


                    ijsVideoService.jumpTo(jumpToTime);
                }
            }
        }
    }]);
})();