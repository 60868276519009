/*jslint browser: true*/
/*global angular, console */
(function () {
    'use strict';
    angular.module('memorabelApp')

        .controller('appController', ['$scope','$state','ChaptersData', function ($scope,$state,ChaptersData) {
            $scope.name = 'appController';
        }])

        .controller('mainController', ['$scope','$state','$stateParams','ChaptersData','ijsVideoService', function ($scope,$state,$stateParams,ChaptersData,ijsVideoService) {

            $scope.chapter      = ChaptersData.getActual();
            $scope.infoPanel    = $stateParams.info;

            $scope.$on("$stateChangeSuccess", function() {

                $scope.infoPanel            = $state.params.info;
                $scope.chapter              = ChaptersData.getActual();
                if(!$scope.chapter && $state.params.cid){
                    ChaptersData.setActual($state.params.cid);
                    $scope.chapter              = ChaptersData.getActual();
                }

                ijsVideoService.timedEvents = $scope.chapter.events;
                $scope.popupPanel           = $state.params.popupid;

            });

            $scope.$on("event:ijs-video/play", function() {
                $state.go('main.chapter.detail',{cid: $state.params.cid,info:'closed'});
            });

            $scope.$on("event:ijs-video/event", function(event, data) {
                var params = $state.params;
                params.popupid = data.infographic;

                ijsVideoService.pause();
                $state.go('main.chapter.detail.popup',params);
            });

            $scope.$on("event:ijs-video/next", function(event, data) {
                var params = $state.params,
                    nextChapter = ChaptersData.getNextChapter(params.cid);

                if(nextChapter){
                    ChaptersData.setActual(nextChapter);
                    $state.go('main.chapter.detail',{cid: nextChapter,info:'closed'});
                }
            });

            $scope.$on("event:ijs-video/ended", function() {
                //if($state.params.cid === "03_stop"){
                    $state.go('main.chapter.detail',{cid: $state.params.cid,info:'open'});
                //}
            });

            $scope.closePopup =  function() {

                var params = $state.params;
                params.popupid = null;

                if(params.cid){
                    $state.go('main.chapter.detail',params);
                }else{
                    $state.go('main.home');
                }
            };

        }])

        .controller('menuController', ['$scope','$state', 'ijsVideoService',function ($scope,$state,ijsVideoService) {

            // which menu-items to show
            if($state.current.name === "main.home") {
                $scope.showHome = true;
            }else{
                $scope.showHome = false;
            }

            // which menu-items to show
            if($state.params.info && $state.params.info  === "open") {
                $scope.showSocial = true;
            }else{
                $scope.showSocial = false;
            }

            $scope.openPopUp = function(){

                var params = $state.params;
                ijsVideoService.pause();
                params.popupid = "info";
                if(params.cid) {
                    // From chapter
                    $state.go('main.chapter.detail.popup', params);
                }else{
                    // From home
                    $state.go('main.home.popup', params);
                }
            }

            $scope.goHome = function(){
                $state.go('main.home', {},{reload:true});
            }

            $scope.hideWarning = function(){
                $scope.hide = true;
            }
        }])

        .controller('homeController', ['$scope','$state', 'ChaptersData','ijsVideoService', function ($scope,$state,ChaptersData,ijsVideoService) {
            $scope.showHome = true;

            $scope.start = function(){

                $scope.showHome = false;
                ijsVideoService.autoplay = true;
                ijsVideoService.play();

                $state.go('main.chapter.detail', {cid: "01_start", info: 'closed'});
            }
        }])

        .controller('chapterController', ['$scope','$state','$stateParams','ChaptersData','ijsVideoService', function ($scope,$state,$stateParams, ChaptersData,ijsVideoService) {
            ChaptersData.setActual($stateParams.cid);

            $scope.chapter              = ChaptersData.getActual();
            $scope.list                 = ChaptersData.getList();

            $scope.navTabClick = function(id){

                if($stateParams.info === "open"){

                    if (ijsVideoService.state !== "PLAYING") {
                        ijsVideoService.play();
                    }

                    $state.go('main.chapter.detail', {cid: $stateParams.cid, info: 'closed'});

                }else{

                    if(id !== $scope.chapter.id){
                        ChaptersData.setActual(id);

                        ijsVideoService.autoplay = false;
                        ijsVideoService.state = "PAUSED";

                        $state.go('main.chapter.detail',{cid: id,info:'open'});
                    }else{
                        if (ijsVideoService.state !== "PAUSED") {
                            ijsVideoService.pause();
                        }

                        $state.go('main.chapter.detail', {cid: id, info: 'open'});
                    }
                }
            }
        }])

        .controller('chapterDetailController', ['$scope','$state', function ($scope,$state) {

            $scope.openInfoGraphic = function(id){
                var params = $state.params;
                params.popupid = id;
                $state.go('main.chapter.detail.popup',params);
            };

        }])

        .controller('popupController', ['$scope','$state', function ($scope,$state) {

        }])
    }()
);
