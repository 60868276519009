/*jslint browser: true*/
/*global angular, console */
(function () {
    "use strict";
    /**
     * Directives for the memorabel-app
     *
     */

    var module = angular.module('memorabelApp.directives', []);


    module.directive('fullScreenButton' , [ function () {
        return {
            restrict: 'E',
            transclude: true,
            replace: true,
            template: '<div ng-click="toggleFullScreen()" class="full-screen-button"></div>',
            link: function (scope, element, attr) {
                if(attr.domId){

                }

                scope.toggleFullScreen = function() {
                    if (!document.fullscreenElement &&    // alternative standard method
                        !document.mozFullScreenElement && !document.webkitFullscreenElement && !document.msFullscreenElement ) {  // current working methods
                        if (document.documentElement.requestFullscreen) {
                            document.documentElement.requestFullscreen();
                        } else if (document.documentElement.msRequestFullscreen) {
                            document.documentElement.msRequestFullscreen();
                        } else if (document.documentElement.mozRequestFullScreen) {
                            document.documentElement.mozRequestFullScreen();
                        } else if (document.documentElement.webkitRequestFullscreen) {
                            document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
                        }

                        element.addClass("exit");

                    } else {
                        if (document.exitFullscreen) {
                            document.exitFullscreen();
                        } else if (document.msExitFullscreen) {
                            document.msExitFullscreen();
                        } else if (document.mozCancelFullScreen) {
                            document.mozCancelFullScreen();
                        } else if (document.webkitExitFullscreen) {
                            document.webkitExitFullscreen();
                        }

                        element.removeClass("exit");

                    }
                }
            }
        }
    }]);

})();