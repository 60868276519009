/*jslint browser: true*/
/*global angular, console */
(function () {
    "use strict";

    var module = angular.module('ijsVideoplayer');

    module.directive('ijsVideoImage', [ '$filter', 'ijsVideoService',function ($filter, ijsVideoService) {
        return {
            restrict: 'E',
            transclude: true,
            replace: true,
            template: '<img class="video-poster-image" ng-show="showImage" ng-src="{{imageSource}}" ng-click="handleClick()" />',
            controller: function($scope, $element){

                $scope.handleClick = function(){
                    ijsVideoService.imageClick();
                }
            },
            link: function link(scope, element,attr) {
                var endVideoListener,
                    pauseListener,
                    playListener,
                    readyListener;
                

                /*
                if(attr.startImage){
                    scope.imageSource  = scope.trustResource(attr.startImage);
                }
                */

                scope.$on('event:ijs-video/ended', function(){
                    scope.imageSource  = scope.trustResource(attr.endImage);
                    scope.showImage = true;
                });

                scope.$on('event:ijs-video/pause', function(){
                    scope.imageSource  = scope.trustResource(attr.pauseImage);
                    scope.showImage = true;
                });

                scope.$on('event:ijs-video/play', function(){
                    scope.showImage = false;
                    scope.imageSource  = '//:0';
                });

                scope.$on('event:ijs-video/ready', function(){
                    scope.$apply(function () {
                        scope.showImage = true;
                        scope.imageSource = scope.trustResource(attr.startImage);
                    });
                });
            }
        }
    }]);
})();