/*jslint browser: true*/
/*global angular, console */
(function () {
    "use strict";

    var module = angular.module('ijsVideoplayer');

    module.directive('ijsVideoControls', ['ijsVideoService', function (ijsVideoService) {
        return {
            restrict: 'E',
            transclude: true,
            replace: true,
            template: '<ul><li ng-click="clickPlayPause()" class="button play-pause"></li></ul>',
            controller: ['$scope', function controller($scope) {
                $scope.name = "controls";


                $scope.togglePlayPause = function togglePlayPause() {
                    if(ijsVideoService.state === 'PLAYING'){
                        $scope.$broadcast("event:ijs-video/pause");
                    }else{
                        $scope.$broadcast("event:ijs-video/play");
                    }
                };
            }],
            link: function link(scope, element, attr) {
                var buttons              = element.find("li");
                var playPauseButton      = buttons[0];

                var pauseEventListener = scope.$on("event:ijs-video/pause",function(e){
                    angular.element(playPauseButton).removeClass("playing");
                })

                var playEventListener = scope.$on("event:ijs-video/play",function(e){
                    angular.element(playPauseButton).addClass("playing");
                })

                var resetEventListener =  scope.$on("event:ijs-video/reset",function(e){
                    angular.element(playPauseButton).removeClass("playing");
                })

                var readyEventListener =  scope.$on("event:ijs-video/ready",function(e){
                    angular.element(playPauseButton).removeClass("playing");
                })

                scope.clickPlayPause = function(){
                    scope.togglePlayPause();
                };
            }
        }
    }]);

})();