/*jslint browser: true*/
/*global angular, console, locale_nl, confirm */

(function () { 'use strict';

    var app = angular.module('memorabelApp',[
        'memorabelApp.services',
        'memorabelApp.directives',
        'ui.router',
        'memorabelApp.routing',
        'ijsVideoplayer',
        'infographic'
    ]);

    app.run([function () {

    }]);
}()
);


// start point to create angular  app
function startMemorabelApp(config) {'use strict';

    function doStartMemorabelApp() {
        // initialize app bases on config values
        var app = angular.module('memorabelApp');

        if (config.CHAPTERDATA_LOCATION) {
            app.constant('CHAPTERDATA_LOCATION', config.CHAPTERDATA_LOCATION);
        } else {
            throw "Please add the property 'CHAPTERDATA_LOCATION' in the config object.";
        }

        if (config.DEBUG) {
            app.constant('DEBUG', config.DEBUG);
        } else {
            throw "Please add the property 'DEBUG' in the config object.";
        }

        // start application
        angular.bootstrap(document, ['memorabelApp']);
    }

    angular.element(document).ready(function () {
        doStartMemorabelApp();
    });

}