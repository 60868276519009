/*jslint browser: true*/
/*global angular, console */
(function () {
    "use strict";

    var module = angular.module('ijsVideoplayer', []);

    module.constant('ijsVideoConfig', {
        REFRESH: 50,
        AUTOPLAY: false,
        TIMELINE_CHANGE: true,
        PRELOAD: 'auto'
    });

    module.directive('ijsVideo',['$sce','ijsVideoConfig','ijsVideoService', function ($sce,ijsVideoConfig, ijsVideoService) {
        return {
            restrict: 'E',
            transclude: true,
            template: '<video ng-src="{{videoSource}}"></video>',
            controller: ['$scope', function controller($scope) {
                $scope.videoElement = {};
                $scope.trustResource = function trustResource(resourceUrl) {
                    return $sce.trustAsResourceUrl(resourceUrl);
                };

                $scope.controls = function controls() {
                    return {

                        play: function play() {
                            $scope.videoElement.play();
                            ijsVideoService.state = "PLAYING"
                        },
                        pause: function pause() {
                            $scope.videoElement.pause();
                            ijsVideoService.state = "PAUSED"
                        }
                    };
                };

                $scope.$on("event:ijs-video/pause",function(e){
                    $scope.playing = false;
                    $scope.controls().pause();
                })

                $scope.$on("event:ijs-video/play",function(e){
                    $scope.playing = true;
                    $scope.controls().play();
                })

                $scope.$on("event:ijs-video/to",function(e,time){
                    $scope.videoElement.currentTime = time;
                })

                $scope.videoOptions = function () {
                    return {
                        autoplay: function (value) {
                            $scope.videoElement.autoplay = !!value;
                        },
                        preload: function preload(value) {
                            $scope.videoElement.preload = value;
                        }
                    };
                };
            }],
            link: function link(scope, element, attr) {
                var canPlayListener,
                    videoEndedListener;

                scope.videoElement = element.find('video')[0];

                scope.videoOptions().preload(ijsVideoConfig.PRELOAD);

                attr.$observe('videoUrl', function () {
                    scope.videoSource = scope.trustResource(attr.videoUrl);

                    //Do not start at the home because on an iPad you have to do this manually
                    if(!canPlayListener && scope.videoSource) {
                        canPlayListener = angular.element(scope.videoElement).on('canplay', function () {

                            if(ijsVideoService.autoplay){
                                scope.$broadcast('event:ijs-video/play');
                            }else{

                                if(ijsVideoService.state !== "READY" && ijsVideoService.state !== "PLAYING"){
                                    scope.$broadcast('event:ijs-video/ready');
                                    ijsVideoService.state = "READY";
                                }
                            }
                        });

                        ijsVideoService.state = "LOADING";
                    }

                    if(!videoEndedListener && scope.videoSource) {
                        videoEndedListener = angular.element(scope.videoElement).on('ended', function () {
                            scope.$broadcast('event:ijs-video/ended');
                            ijsVideoService.state = "ENDED";
                        });
                    }
                });
            }
        }
    }])

    module.service('ijsVideoService', ['$rootScope', 'ijsVideoConfig', function ($rootScope, ijsVideoConfig) {

        var service = {};
        service.state = "";
        service.autoplay = ijsVideoConfig.AUTOPLAY;
        service.timedEvents = {};

        service.play = function(){
            service.state = "PLAYING";
            $rootScope.$broadcast('event:ijs-video/play');
        };

        service.pause = function(){
            service.state = "PAUSE";
            $rootScope.$broadcast('event:ijs-video/pause');
        };

        service.reset = function(){
            $rootScope.$broadcast('event:ijs-video/reset');
        };

        service.jumpTo = function(time){
            $rootScope.$broadcast('event:ijs-video/to',time);
        };

        service.hasEvent = function(time){
            var result = false;

            if(service.timedEvents){
                angular.forEach(service.timedEvents, function (timedEvent) {
                    if(time > timedEvent.start && time < timedEvent.end){
                        result = timedEvent.data;
                    }
                });
            }

            return result;
        };

        service.eventClick = function(data){
            $rootScope.$broadcast('event:ijs-video/event',data);
        };

        service.imageClick = function(){

            if(service.state === "ENDED"){
                $rootScope.$broadcast('event:ijs-video/next');
            }else{
                $rootScope.$broadcast('event:ijs-video/play');
            }

        };

        return service;
    }]);

})();