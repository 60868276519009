/*jslint browser: true*/
/*global angular, console */
(function () {
    'use strict';

    var app = angular.module('memorabelApp');

    /**
     * @ngdoc directive
     * @name socialMedia
     *
     * @description shows the social media buttons to share this application
     *
     */
    app.directive('socialMedia', ['$state', '$injector', function ($state, language,$injector) {
        return {
            restrict: 'E',
            template: '<div class="social-media-container">' +
                '<div class="social-media-icon icon-mail" ng-click="mailSharing()"></div>' +
                '<div class="social-media-icon icon-facebook" ng-click="facebookSharing()"></div>' +
                '<div class="social-media-icon icon-twitter" ng-click="twitterSharing()"></div>' +
            '</div>',
            controller: ['$scope', '$window', function($scope, $window){
/*
                SOCIAL_TWITTER_HOME
                SOCIAL_TWITTER_VIDEO
                SOCIAL_TWITTER_ACTOR
                SOCIAL_FACEBOOK_HOME
                SOCIAL_FACEBOOK_VIDEO
                SOCIAL_FACEBOOK_ACTOR
                SOCIAL_HOME_DESCRIPTION
                SOCIAL_VIDEO_DESCRIPTION
                SOCIAL_ACTOR_DESCRIPTION
                SOCIAL_LINKEDIN_HOME
                SOCIAL_LINKEDIN_VIDEO
                SOCIAL_LINKEDIN_ACTOR
                SOCIAL_PINTEREST_HOME
                SOCIAL_PINTEREST_VIDEO
                SOCIAL_PINTEREST_ACTOR
*/
                var title = "Hoe stop je Alzheimer";

                function openShareWindow (url) {
                    $window.open(url,'sharer','toolbar=0,status=0,width=626,height=436');
                }

                function openMailWindow (url) {
                    $window.open('mailto:?subject=Deel "Hoe stop je Alzheimer"&body=Hoi,%0D%0A%0D%0Aik heb de informatieve webfilm "Hoe stop je Alzheimer?" zojuist bekeken en denk dat het ook voor jou interessant kan zijn om hem te bekijken.%0D%0A%0D%0AOver de webfilm%0D%0AHoe stop je Alzheimer? is een informatieve webfilm van Alzheimer Nederland en het VU medisch centrum. In de webfilm wordt uitgelegd wat Alzheimer met je doet en laat de nieuwe wegen zien die de onderzoekers aan het VUmc bewandelen om de ziekte te stoppen. %0D%0A%0D%0A Link: www.hoe-stop-je-alzheimer.nl','Mail');
                }

                function getUrl () {
                    //http://stackoverflow.com/questions/9610156/using-hash-tags-with-facebook-share-possible
                    return encodeURI($window.location.href).replace('#', '%23');
                }


                function substituteUrl (url, replacements) {
                    // replace all of the parameters [] in the url string
                    for (var i = 0; i < replacements.length; i++) {
                        url = url.replace(new RegExp('\\['+i+'\\]', 'g'), replacements[i]);
                    }

                    return url;
                }

                // urls social media:
                // http://petragregorova.com/articles/social-share-buttons-with-custom-icons/
                // http://stackoverflow.com/questions/12448134/social-share-links-with-custom-icons

                $scope.facebookSharing = function () {
                    //http://www.facebook.com/share.php?u=[URL]&title=[TITLE]
                    var url = getUrl();
                    openShareWindow(substituteUrl('http://www.facebook.com/share.php?u=[0]&title=[1]', [url,title]));
                };
                $scope.mailSharing = function () {
                    openMailWindow ();
                };
                $scope.twitterSharing = function () {
                    var url = getUrl();
                    openShareWindow(substituteUrl('http://twitter.com/intent/tweet?status=[1]+[0]', [url,title]));
                };
            }]
        };

    }]);

    /*
    // update meta data based on current url
    app.run(['$rootScope', '$document', '$state', 'getAbsoluteUrl', '$injector',
        function ($rootScope, $document, $state, getAbsoluteUrl, $injector) {
            var head, ogDescription, ogImage;
            head = angular.element($document[0].head);
            ogImage = angular.element('<meta property="og:image" content="">');
            ogDescription = angular.element('<meta property="og:description" content="">');

            head.append(ogDescription);
            head.append(ogImage);

            $rootScope.$on('$stateChangeSuccess', function () {

                var description = getSocialDescription($state, $injector);
                var imgUrl = getAbsoluteUrl(getPathToSocialImg($state, $injector));

                ogImage.attr('content', imgUrl);
                ogDescription.attr('content', description);

            });
        }]);
        */
}());