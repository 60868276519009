/*jslint browser: true*/
/*global angular, console */
(function () {
    "use strict";

    var module = angular.module('infographic', []);

    module.directive('infographicContainer',[ function () {
        return	{
            restrict: 'E',
            replace: true,
            transclude: true,
            scope:{},
            templateUrl: 'views/infographic/infographic-container.html',
            controller:['$scope','$element','$attrs',function($scope,$element,$attrs){

                $scope.infographics         = [];
                $scope.currentInfographic   = 0;
                $scope.title                = $attrs.title

                this.addInfographic = function(infographic) {
                    $scope.infographics.push(infographic);
                };
                console.log($attrs);
                $scope.showThisInfographic   = function(index) {
                    var zoom;

                    if(index !== $scope.currentInfographic){

                        if (index > $scope.currentInfographic) {
                            zoom = "in";
                        } else {
                            zoom = "out";
                        }

                        var stepSize = Math.abs($scope.currentInfographic - index);

                        if(stepSize > 1 && $attrs.skip === "true"){
                            //Hide current
                            $scope.infographics[$scope.currentInfographic].hide(zoom,0);

                            if(zoom === "in"){
                                //Show next briefly
                                $scope.infographics[$scope.currentInfographic + 1].skip(zoom,0.2);
                            }else{
                                //Show previous briefly
                                $scope.infographics[$scope.currentInfographic - 1].skip(zoom,0.2);
                            }

                            //Show target
                            $scope.infographics[index].show(zoom,0.8);
                            $scope.currentInfographic = index;

                        }else {
                            $scope.infographics[$scope.currentInfographic].hide(zoom,0);
                            $scope.infographics[index].show(zoom,0.2);
                            $scope.currentInfographic = index;
                        }
                    };
                }
            }],
        };
    }]);

    module.directive("infographic",[ function() {
            return	{
                restrict: 'E',
                replace: true,
                transclude: true,
                require: '^?infographicContainer',
                scope:{},//TODO: set right scope
                templateUrl: 'views/infographic/infographic.html',
                controller:['$scope','$element','$attrs',function($scope,$element,$attrs){
                    var hotspotIndex    = 0;
                    var currentHotspot  = -1;
                    var hotspots        = [];

                    this.addHotspot = function(hotspot) {
                        hotspot.index = hotspotIndex;
                        hotspots.push(hotspot);
                        hotspotIndex++;
                        console.log(hotspot);

                    };

                    this.showHotspot   = function(index) {
                        if(index !== currentHotspot){
                            if(currentHotspot !== -1){
                                hotspots[currentHotspot].hide();
                            }
                            hotspots[index].show();
                        }
                        currentHotspot = index;
                    }

                    this.showFirst   = function() {
                        showHotspot(0)
                    }

                }],
                link: function(scope, element, attrs, infographicContainerController) {
                    var images  = element.find('img');
                    var info    = element.find('div');
                    var imageElement    = angular.element(images[0]);
                    var infoElement     = angular.element(info[0]);

                    infographicContainerController.addInfographic(scope);

                    scope.image     =  attrs.bgImage;
                    scope.title     = attrs.title;

                    scope.hide = function(zoom,delay){

                        var scale = 1;
                        infoElement.css("display","none");



                        // Tween when zoom or when a zoom is defined but only in opposite direction
                        if(attrs.zoom == "both" || (attrs.zoom && attrs.zoom !== zoom)) {
                            if (zoom == "in") {
                                scale = '2'
                            } else {
                                scale = '0.2'
                            }

                            TweenMax.to(element, 1,{
                                delay:delay,
                                opacity: 0
                            });
                        }else{
                            element.css("opacity",'0');
                        }

                        TweenMax.to(imageElement, 1, {
                            delay:delay,
                            scale: scale,
                            onComplete: function(){
                                imageElement.css("scale",'1');
                            }
                        });
                    }

                    scope.skip = function(zoom, delay){
                        var fromScale = 1,
                            toScale = 1;


                        if(attrs.zoom == "both") {
                            if (zoom == "in") {
                                fromScale = '0.5';
                                toScale = '1.5';
                            } else {
                                fromScale = '1.5';
                                toScale = '0.5';
                            }
                        }

                        TweenMax.to(element, 0.5, {
                            delay: delay,
                            opacity: 1,
                            onComplete: function(){
                                TweenMax.to(element, 0.5, {
                                    opacity: 0
                                });
                            }
                        });


                        TweenMax.fromTo(imageElement, 1, {
                            delay: delay,
                            scale: fromScale
                        },
                        {
                            delay: delay,
                            scale: toScale
                        });
                    }

                    scope.show = function(zoom,delay){
                        var scale = 1;

                        if(attrs.zoom == "both" || attrs.zoom === zoom) {
                            if (zoom === "in") {
                                scale = '0.2'
                            } else {
                                scale = '2'
                            }

                            TweenMax.to(element, 1,{
                                delay:delay,
                                opacity: 1,
                                onComplete: function(){
                                    infoElement.css("display","block");
                                }
                            });
                        }else {
                            infoElement.css("display", "block");
                            element.css("opacity", 1);
                            TweenMax.fromTo(infoElement, 1, {
                                    opacity: 0
                                },
                                {
                                    opacity: 1
                                });

                        }

                        TweenMax.fromTo(imageElement, 1, {
                            delay:delay,
                            scale: scale
                        },
                        {
                            delay:delay,
                            scale: 1
                        });

                    }
                }
            };
        }]
    );

})();