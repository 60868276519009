/*jslint browser: true*/
/*global angular, console, navigator */
(function () {
    'use strict';
    var app = angular.module('memorabelApp.routing', []);

    /**
     * Configure states to views
     *
     * @see https://github.com/angular-ui/ui-router/wiki for information about the routing options
     */

    app.config(['$stateProvider', '$urlRouterProvider', function ($stateProvider, $urlRouterProvider) {
        $stateProvider
            .state('main', {
                url: '/main',
                views: {
                    '': {
                        templateUrl: 'views/main.html',
                        controller : 'mainController',
                        resolve: {
                            Chapter: function (ChaptersData) {
                                return ChaptersData.get();
                            }
                        }
                    }
                }
            })
            .state('main.home', {
                url: '/home',
                views: {
                    'menu': {
                        templateUrl: 'views/menu.html',
                        controller: 'menuController'
                    },
                    'chapter': {
                        templateUrl: 'views/chapter.html',
                        controller: 'chapterController'
                    },
                    'home': {
                        templateUrl: 'views/home.html',
                        controller: 'homeController'
                    }
                }
            })
            .state('main.home.popup', {
                url: "/popup/:popupid",
                views: {
                    'popup@main': {
                        templateUrl: function (params) {
                            return 'views/popups/' + params.popupid + ".html";
                        },
                        controller: 'popupController'
                    }
                }
            })
            .state('main.chapter', {
                url: '/chapter/:cid/:info',
                views: {
                    'menu': {
                        templateUrl: 'views/menu.html',
                        controller: 'menuController'
                    },
                    'chapter': {
                        templateUrl: 'views/chapter.html',
                        controller: 'chapterController'
                    }
                }
            })
            .state('main.chapter.detail', {
                url: '/detail',
                views: {
                    'chapterdetail': {
                        templateUrl: function (params) {
                            return 'views/chapterdetail/' + params.cid + ".html";
                        },
                        controller: 'chapterDetailController'
                    }
                }
            })
            .state('main.chapter.detail.popup', {
                url: "/popup/:popupid",
                views: {
                    'popup@main': {
                        templateUrl: function (params) {
                            return 'views/popups/' + params.popupid + ".html";
                        },
                        controller: 'popupController'
                    }
                }
            })

        $urlRouterProvider.otherwise('main/home');
    }])

}());