/*jslint browser: true*/
/*global angular, console */
(function () {
    "use strict";
    /**
     * Service is responsible for loading content
     *
     */

    var app = angular.module('memorabelApp.services', []);


    app.service('ChaptersData', function($http, $q, CHAPTERDATA_LOCATION){
        var actual;

        var ChaptersData = {
            data: null,
            get: function () {
                if (this.data) {
                    var defer = $q.defer();
                    defer.resolve(this.data);
                    return defer.promise;
                } else {
                    return $http.get(CHAPTERDATA_LOCATION)
                        .then(function(result) {
                            ChaptersData.data = result.data;
                            return result.data;
                        });
                }

            },
            setActual: function(id) {
                actual = id;
            },
            getList: function() {
                return ChaptersData.data;
            },
            getActual: function() {
                /*
                if(!actual){
                    ChaptersData.setActual(ChaptersData.data[0].id);
                }
                */
                return ChaptersData.getById(actual);

            },
            getById: function(id) {
                var data = false;

                angular.forEach(ChaptersData.data, function(chapter, key) {
                    if(chapter.id === actual){
                        data = chapter;
                    }
                });

                return data;
            },
            getNextChapter: function(id) {
                var chapterKey = -1,
                    nextChapterKey,
                    nextChapter;

                angular.forEach(ChaptersData.data, function(chapter, key) {
                    if(chapter.id === id){
                        chapterKey = key;
                    }
                });

                if(chapterKey > -1){
                    nextChapterKey = chapterKey + 1;
                    nextChapter = ChaptersData.data[nextChapterKey];
                    if(nextChapter){
                        return nextChapter.id;
                    }
                }

                return false;
            }
        };

        return ChaptersData;
    });

})();
