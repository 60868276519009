/*jslint browser: true*/
/*global angular, console */
(function () {
    "use strict";

    var module = angular.module('infographic');

    module.directive("hotspot",[
        function() {
            return	{
                restrict: 'E',
                replace: true,
                transclude: true,
                require: '^?infographic',
                scope:{},//TODO: set right scope
                templateUrl: 'views/infographic/infographic-hotspot.html',
                link: function(scope, element, attrs, infographicController) {

                    scope.posX           = attrs.positionX;
                    scope.posY           = attrs.positionY;
                    scope.textPositionX  = attrs.textPositionX;
                    scope.textPositionY  = attrs.textPositionY;
                    scope.index;

                    infographicController.addHotspot(scope);

                    var children        = element.find('div');
                    var info            =  angular.element(children[1]);
                    var infoChildren    = info.find('div');
                    var deltaY = attrs.positionY - attrs.textPositionY + 10;
                    var deltaX = attrs.positionX - attrs.textPositionX + 5;
                    var radians = Math.atan2(deltaY, deltaX);

                    radians += ( Math.PI);

                    var angle = Math.round(radians * (180 / Math.PI));
                    var line = document.createElement('div');

                    line.setAttribute("class","hotspot-line");

                    line.style.width    = (pythagoras(deltaY, deltaX) - 18) + "px"
                    line.style.top      = (parseFloat(attrs.positionY) + 15) + "px";
                    line.style.left     = (parseFloat(attrs.positionX) + 15) + "px";

                    line.style.webkitTransform = "rotate(" + angle + "deg)";
                    line.style.msTransform = "rotate(" + angle + "deg)";
                    line.style.transform = "rotate(" + angle + "deg)";

                    info.append(line);

                    function pythagoras(a, b) {
                        var a = parseFloat(a);
                        var b = parseFloat(b);
                        var c = Math.sqrt(a*a + b*b);
                        return c;
                    }

                    scope.hide = function(){
                        TweenMax.to(
                            info, 0.3,
                            {
                                opacity: 0,
                                onComplete: function(){
                                    info.css("display",'none');
                                    info.css("opacity",'1');
                                }
                            }
                        );
                    }

                    scope.show = function(){
                        info.css("display",'block');
                        TweenMax.from(info, 0.3,
                            {
                                opacity: 0
                            }
                        );
                    }

                    scope.showHotspotInfo = function(){
                        infographicController.showHotspot(scope.index)
                    }

                    if(scope.index === 0){
                        infographicController.showHotspot(0)
                    }
                }
            };
        }
    ]);

})();