/*jslint browser: true*/
/*global angular, console */
(function () {
    "use strict";

    var module = angular.module('ijsVideoplayer');

    module.directive('ijsVideoFeedback', ['$sce', function ($sce) {
        return {
            restrict: 'E',
            transclude: true,
            replace: true,
            controller: ['$rootScope', '$scope', '$interval', '$window', 'ijsVideoConfig','ijsVideoService', function controller($rootScope, $scope, $interval, $window, ijsVideoConfig,ijsVideoService) {
                var lastTime = 0;
                var requiredProperties = ['duration', 'currentTime', 'volume', 'playbackRate'];
                var interval  = false;

                $scope.interval = {};
                $scope.lastUpdate = 0;
                $scope.duration = 0;
                $scope.volume = 1;
                $scope.playbackRate = 1;
                $scope.currentTime = 0;
                $scope.percentagePlayed = 0;
                $scope.buffered = 0;
                $scope.buffering = false;


                $scope.grabStatistics = function grabStatistics() {

                    var player = $scope.videoElement;

                    // Determine if we're currently buffering.
                    if (lastTime === player.currentTime && !player.paused) {
                        $scope.buffering = true;
                        return;
                    }

                    // Log the last time and ensure we're not displaying the buffering message.
                    lastTime = player.currentTime;
                    $scope.buffering = false;

                    // Iterate over each property we wish to listen to.
                    angular.forEach(requiredProperties, function forEach(property) {
                        $scope[property] = !isNaN($scope.videoElement[property]) ? $scope.videoElement[property] : $scope[property];
                    });

                    if ($scope.videoElement.buffered.length !== 0) {
                        $scope.buffered = Math.round($scope.videoElement.buffered.end(0) / player.duration) * 100;
                    }

                    if ($scope.videoElement.muted) {
                        $scope.volume = 0;
                    }

                    // Calculate other miscellaneous properties.
                    $scope.percentagePlayed = ($scope.currentTime / $scope.duration) * 100;

                    // Notify everybody that the statistics have been updated!
                    $scope.lastUpdate = new $window.Date().getTime();

                };

                $scope.beginPolling = function beginPolling() {
                    // Update the statistics every so often.
                    if(!interval){
                        interval = $interval($scope.grabStatistics, ijsVideoConfig.REFRESH);
                    }

                };

                $scope.endPolling = function endPolling() {

                    $interval.cancel(interval)
                    interval = false;
                };


                /**
                 * @method updateVolume
                 * @return {void}
                 */
                var updateVolume = function updateVolume() {
                    $scope.volume = $scope.videoElement.volume;
                };

                // When we need to force the refreshing of the volume.
                //$scope.$on('ng-video/volume', updateVolume);

                // When we need to force the refreshing of the properties.
                //$scope.$on('ng-video/feedback/refresh', $scope.grabStatistics);

                var playListener = $scope.$on('event:ijs-video/play',function (e) {
                    // Update the statistics once.
                    $scope.grabStatistics();
                    $scope.beginPolling();

                });

                var pauseListener = $scope.$on('event:ijs-video/pause',function (e) {
                    // Update the statistics once.
                    $scope.endPolling();
                });

                var resetListener = $scope.$on('event:ijs-video/reset',function (e) {

                    $scope.endPolling();
                    $scope.videoElement.currentTime = 0;
                    $scope.grabStatistics();
                });

                var readyListener = $scope.$on('event:ijs-video/ready',function (e) {

                    $scope.endPolling();
                    $scope.videoElement.currentTime = 0;
                    $scope.grabStatistics();
                });

                // Monitor the status of the video player.
                $scope.$watch('playing', function (playing, oldVal) {
                    // Update the statistics once.
                    $scope.grabStatistics();

                    if (playing) {

                        // Update the statistics periodically.
                        $scope.beginPolling();
                        return;

                    }

                    $scope.endPolling();
                },true);

            }]
        }
    }])
})();